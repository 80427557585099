<template>
  <div class="animated fadeIn">
    <b-tabs
      v-model="tabIndex"
      justified
      tabs
    >
      <b-tab
        title="Общие данные компании"
        :active="active =='general'"
      >
        <shared-data />
      </b-tab>
      <b-tab
        title="Реквизиты"
        :active="active =='requisites'"
      >
        <requisites />
      </b-tab>
      <b-tab
        title="Контактная информация"
        :active="active =='contacts'"
      >
        <contractor-contacts-list />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ContractorContactsList from '../views/ContactRequisites/ContractorContactsList.vue';
import Requisites from '../views/Requisites.vue';
import SharedData from '../views/SharedData.vue';
import {queryToUrl} from '../services/http';

export default {
  name: 'Profile',
  components: {ContractorContactsList, Requisites, SharedData},
  props: ['active'],
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter(
        (route) => route.name || route.meta.label,
      );
    },
  },
  watch: {
    tabIndex() {
      const tab = queryToUrl('', {tab: this.tabIndex});
      history.pushState(null, null, tab);
    },
  },
  created() {
    const queries = this.$router.currentRoute.query;
    if (queries.tab) {
      this.tabIndex = Number(queries.tab);
    }
  },
};
</script>
