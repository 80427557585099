<template>
  <div class="animated fadeIn">
    <div v-if="list.length">
      <b-card
        v-for="contact in list"
        :key="contact.id"
        no-body
      >
        <b-card-header>{{ contact.name }}</b-card-header>
        <b-card-body>
          <div class="row mb-4">
            <div
              v-if="contact.role"
              class="col-sm-3"
            >
              Роль
            </div>
            <div
              v-if="contact.role"
              class="col-sm-9 mb-2"
            >
              {{ contact.role }}
            </div>

            <div
              v-if="contact.fullname"
              class="col-sm-3"
            >
              ФИО
            </div>
            <div
              v-if="contact.fullname"
              class="col-sm-9 mb-2"
            >
              {{ contact.fullname }}
            </div>

            <div
              v-if="contact.position"
              class="col-sm-3"
            >
              Должность
            </div>
            <div
              v-if="contact.position"
              class="col-sm-9 mb-2"
            >
              {{ contact.position }}
            </div>

            <div
              v-if="contact.phone"
              class="col-sm-3"
            >
              Телефон
            </div>
            <div
              v-if="contact.phone"
              class="col-sm-9 mb-2"
            >
              <a :href="`tel:${contact.phone}`">
                <b>{{ contact.phone }}</b>
              </a>
            </div>

            <div
              v-if="contact.mobilePhone"
              class="col-sm-3"
            >
              Мобильный телефон
            </div>
            <div
              v-if="contact.mobilePhone"
              class="col-sm-9 mb-2"
            >
              <a :href="`tel:${contact.mobilePhone}`">
                <b>{{ contact.mobilePhone }}</b>
              </a>
            </div>

            <div
              v-if="contact.email"
              class="col-sm-3"
            >
              Email
            </div>
            <div
              v-if="contact.email"
              class="col-sm-9 mb-2"
            >
              <a :href="`mailto:${contact.email}`">
                <b>{{ contact.email }}</b>
              </a>
            </div>

            <div
              v-if="contact.icq"
              class="col-sm-3"
            >
              ICQ
            </div>
            <div
              v-if="contact.icq"
              class="col-sm-9 mb-2"
            >
              {{ contact.icq }}
            </div>

            <div
              v-if="contact.skype"
              class="col-sm-3"
            >
              Skype
            </div>
            <div
              v-if="contact.skype"
              class="col-sm-9 mb-2"
            >
              {{ contact.skype }}
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <p class="h6 text-center p-3">
        Нет данных для отображения
      </p>
    </div>
  </div>
</template>

<script>
import {contractorsContactRequisitesList} from '../../services/api';

export default {
  name: 'ContractorContactsList',
  components: {},
  props: ['contractorId'],

  data: function() {
    return {
      loading: false,
      list: [],
      propertyList: {
        role: 'Роль',
        fullname: 'ФИО',
        position: 'Должность',
        email: 'Email',
        phone: 'Телефон',
        mobilePhone: 'Мобильный телефон',
        icq: 'ICQ',
        skype: 'Skype',
      },
    };
  },
  mounted() {
    this.contractorsContactRequisitesList();
  },
  methods: {
    async contractorsContactRequisitesList() {
      this.loading = true;
      const response = await contractorsContactRequisitesList(this.$store.state.user.ownerId, {
        limit: 100,
      });
      if (response && response.status === 200) {
        this.list = response.data.items;
      }
      this.loading = false;
    },
  },
};
</script>
